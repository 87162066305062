$color-primary: #ffb339;
$color-secondary: #121212;

$text-color-primary: #121212;
$text-color-secondary: #6a6a6a;
$booyah-color: #f99d04;
$danger: #eb2336;
$success: #0abd3d;
$info: #01a8f4;
$warning: #ffe93b;
