@import "reset";
@import "variables";
@import "global";
@import "../../node_modules/ngx-toastr/toastr";

// calendario

.c-calendar {
  font-family: "Montserrat", sans-serif !important;

  .c-calendar-month {
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 3px 6px #00000029;

    .c-calendar-month-1 {
      border-radius: 0px !important;
      background: #ffffff !important;
      box-shadow: none !important;
      position: relative;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 25px 0 !important;
      margin: 0 30px;

      .c-calendar-middle {
        margin: 0 !important;
        width: 100%;

        .c-calendar-month-header {
          text-align: left !important;
          text-transform: none !important;
          font-size: 16px !important;
          color: $color-secondary;
        }
      }

      .c-calendar-left {
        position: absolute;
        right: 38px;
        top: 50%;
        transform: translate(0, -50%);

        .c-calendar-arrow {
          &::before {
            content: "\f104";
          }
        }
      }

      .c-calendar-right {
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translate(0, -50%);

        .c-calendar-arrow {
          &::before {
            content: "\f105";
          }
        }
      }

      .c-calendar-arrow {
        position: relative;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        border: 1px solid #707070;
        transition: all 200ms ease-in-out;
        user-select: none;

        &:hover {
          background: #dedede;
          color: #dedede;
        }

        &::before {
          content: "";
          font-weight: 900;
          font-family: "Font Awesome 5 Free" !important;
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          display: inline-block;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          line-height: 1;
          color: $color-primary;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .c-calendar-month-2 {
      box-shadow: none !important;
      border-radius: 0px !important;
      padding: 16px 30px !important;

      .c-calendar-month-header {
        flex-flow: row;
        justify-content: space-between;
        margin-bottom: 12px !important;
        padding-bottom: 0px !important;
        border-bottom-width: 0px !important;
        .c-calendar-month-item {
          flex-grow: unset !important;
          .c-calendar-month-date {
            font-weight: 700;
            text-transform: none !important;
            color: rgba(18, 18, 18, 0.4);
          }
        }
      }

      .c-calendar-month-body {
        .c-calendar-month-row {
          justify-content: space-between;
          flex-flow: row;
          .c-calendar-month-item {
            flex-grow: unset !important;
            min-width: 32px;
            margin-top: 12px !important;
            font-weight: 700;
            color: $color-secondary;

            &:hover {
              .c-calendar-month-column {
                border-bottom-width: 0px !important;
              }
            }

            &.last-month {
              opacity: 0.2;
            }

            .c-calendar-month-column {
              border-radius: 50%;
              height: 34px;
              width: 34px;
              text-align: center;
              line-height: 34px;
              display: inline-block;

              &.c-calendar-month-column-active {
                border-bottom-width: 0px !important;
                opacity: 1;
              }

              &.c-calendar-month-column-has-event {
                color: #121212 !important;
                background: $color-primary;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}
