@import "variables";

.container {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  padding: 0 5%;
}

// buttons
.btn {
  position: relative;
  display: inline-block;
  border-radius: 6px;
  font-weight: 800;
  color: $color-secondary;
  text-transform: uppercase;
  padding: 16px 50px;
  font-size: 16px;

  &-default {
    background: $color-primary;

    &:hover {
      background: $color-secondary;
      color: $color-primary;
    }
  }

  &-secondary {
    background: $color-secondary;
    color: $color-primary;

    &:hover {
      background: $color-primary;
      color: $color-secondary;
    }
  }

  &-small {
    padding: 10px 15px;
    font-size: 12px;
    height: auto;
  }

  &-large-x {
    padding: 28px 50px;
    font-size: 16px;
    height: auto;
  }

  &-block {
    display: block;
    width: 100%;
    text-align: center;
  }

  &-link {
    display: inline-block;
    font-size: 14px;
    text-transform: uppercase;
    color: $color-primary;
    font-weight: 700;
    position: relative;
    text-align: center;

    &::before {
      content: "";
      position: absolute;
      bottom: -3.5px;
      left: 0;
      width: 100%;
      height: 1px;
      background: $color-primary;
      opacity: 0.4;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: -3.5px;
      left: 0;
      width: 100%;
      height: 1px;
      opacity: 0;
      transform: scaleX(0);
      background: $color-primary;
      transition: all 200ms ease-in-out;
    }

    &:hover {
      text-decoration: none;

      &::after {
        opacity: 1;
        transform: scaleX(1);
      }
    }
  }

  &:hover {
    text-decoration: none;
  }
}
// end buttons

// titles
.title {
  display: flex;
  flex-flow: row;
  align-items: center;

  &__name {
    font-size: 52px;
    font-weight: 400;
    color: $text-color-primary;
    padding-right: 20px;
    position: relative;
    line-height: 52px;

    strong {
      font-weight: 700;
      color: $color-primary;
    }

    &::before {
      content: "";
      height: 35px;
      background: $color-primary;
      width: 1px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__content {
    font-size: 14px;
    font-weight: 400;
    color: $text-color-primary;
    line-height: 1;
    margin-left: 20px;
  }

  &-dark {
    .title__name {
      color: #ffffff;
    }
    .title__content {
      color: #ffffff;
    }
  }
}
// end titles

// form
.form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  &-control {
    flex: 0 0 100%;
    width: 100%;
    position: relative;
    margin-bottom: 10px;

    .fas {
      position: absolute;
      top: 50%;
      right: 13px;
      transform: translate(0, -50%);
      color: $color-primary;
      pointer-events: none;
    }

    input {
      width: 100%;
      padding: 0 20px;
      height: 42px;
      border: 1px solid $text-color-secondary;
      border-radius: 6px;
      font-size: 14px;
      background: transparent;
    }

    select {
      width: 100%;
      padding: 0 20px;
      height: 42px;
      border: 1px solid $text-color-secondary;
      border-radius: 6px;
      font-size: 14px;
      outline: 0;
      -webkit-appearance: none;
      appearance: none;
      background: transparent;
    }

    textarea {
      width: 100%;
      padding: 12px 20px;
      border: 1px solid $text-color-secondary;
      border-radius: 6px;
      resize: none;
      font-size: 14px;
      background: transparent;
    }

    &.half {
      flex: 0 0 49%;
      width: 49%;
    }

    &.third {
      flex: 0 0 32.33%;
      width: 32.33%;
    }

    &.two-third {
      flex: 0 0 65.66%;
      width: 65.66%;
    }

    &__hint {
      font-size: 12px;
      color: $danger;
    }
  }

  .btn {
    padding: 12px 0;
    font-size: 14px;
  }
}
// end form

// toggle
.toggle {
  display: flex;
  flex-flow: row;
  align-items: flex-start;

  &-item {
    input[type="radio"] {
      display: none;

      &:checked + label {
        background: $color-primary;
        color: rgba(18, 18, 18, 1);
      }
    }

    label {
      display: inline-block;
      height: 32px;
      line-height: 32px;
      border: 1px solid $color-primary;
      font-size: 11px;
      font-weight: 700;
      text-transform: uppercase;
      color: rgba(18, 18, 18, 0.5);
      border-radius: 6px;
      padding: 0 24px;
      transition: all 200ms ease;

      &:hover {
        cursor: pointer;
        color: rgba(18, 18, 18, 1);
      }
    }

    & + .toggle-item {
      position: relative;
      left: -8px;
    }
  }
}
// end toggle

// filters
.filters {
  display: flex;
  flex-flow: row;
  align-items: center;

  &__title {
    font-size: 14px;
    font-weight: 400;
  }

  &-list {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }

  &-item {
    margin-right: 35px;
    margin-bottom: 10px;
    margin-top: 10px;
    input[type="radio"],
    input[type="checkbox"] {
      display: none;

      &:checked + label {
        &::before {
          content: "\f00c";
          background: $color-primary;
        }
      }
    }

    label {
      font-size: 14px;
      font-weight: 400;
      padding-left: 32px;
      display: inline-block;
      position: relative;

      &::before {
        content: "";
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
        font-weight: 900;
        font-family: "Font Awesome 5 Free";
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        border-radius: 6px;
        border: 1px solid $color-primary;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: all 200ms ease;
      }
      &:hover {
        cursor: pointer;

        &::before {
          content: "\f00c";
          background: $color-primary;
        }
      }
    }
  }
}
// end filters

// banner
.banner {
  &-item {
    &__title {
      p {
        line-height: 1.2;
      }
    }
  }
}
// end banner

// times
.teams {
  &-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }

  &-item {
    flex: 0 0 48%;
    width: 48%;
    margin-top: 40px;

    &__title {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 14px;
      display: block;
      color: #878787;
      text-transform: uppercase;
    }

    &__box {
      display: block;
      position: relative;
    }

    &__image {
      min-height: 320px;
      position: relative;

      img {
        height: 320px;
        border-radius: 6px;
        display: block;
        width: 100%;
        object-fit: cover;
      }
    }

    &__overlay {
      height: 320px;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: rgba(255, 179, 57, 0.6);
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      opacity: 0;
      pointer-events: none;
      transition: all 200ms ease-in-out;

      span {
        text-transform: uppercase;
        text-align: center;
        font-weight: 700;
        padding: 16px 30px;
        border-radius: 6px;
        background: $color-secondary;
        color: $color-primary;
      }
    }

    &:hover {
      .teams-item {
        &__overlay {
          opacity: 1;
        }
      }
    }
  }
}
// end times

// box-teams
.box-teams {
  &-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  &-item {
    flex: 0 0 100%;
    width: 100%;
    position: relative;

    &__image {
      position: relative;
      min-height: 240px;
      overflow: hidden;
      pointer-events: none;

      img {
        display: block;
        object-fit: cover;
        width: 100%;
        height: 240px;
      }
    }

    &__content {
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 2;
      transform: translate(0, -50%);
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 5%;
    }

    &__title {
      font-size: 40px;
      font-weight: 700;
      text-transform: uppercase;
      color: #ffffff;
      margin-right: 8px;
    }

    &:not(:nth-child(3n)) {
      margin-right: 3%;
    }
  }
}
// end box-teams

// teams
.teams {
  &-item {
    &__image {
      min-height: 160px;

      img {
        height: 160px;
      }
    }
  }
}
// end teams

// matches
.matches {
  &-item {
    width: 48%;
    flex: 0 0 48%;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    box-shadow: 0px 3px 6px #00000066;
    border-radius: 6px;
    padding: 22px 40px 22px 83px;
    margin-bottom: 50px;
    transition: all 300ms ease-in-out;
    position: relative;

    &__live {
      position: absolute;
      top: 8px;
      right: 8px;
      background: #e70017;
      border-radius: 6px;
      padding: 2px 4px;
      font-size: 12px;
      font-weight: 600;
      color: #ffffff;
      z-index: 99;

      &:hover {
        background: $danger;
        text-decoration: none;
      }

      &--primary {
        background: $color-primary;
        color: $color-secondary;

        &:hover {
          background: $color-primary;
          opacity: 0.6;
        }
      }
    }

    &__logo {
      min-width: 92px;
      position: relative;

      span {
        text-align: center;
        width: 100%;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        display: block;
        margin-top: 6px;
      }

      img {
        width: 92px;
        object-fit: contain;
        display: block;
        margin: 0 auto;
      }
    }

    &__content {
      flex: 1;
      width: 100%;
      padding: 0 5%;
      text-align: center;
    }

    &__game {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 4.2px;
      text-transform: uppercase;
      color: $color-primary;
      white-space: nowrap;
    }

    &__score {
      font-size: 72px;
      line-height: 1.2;
      white-space: nowrap;

      &.empty {
        font-size: 48px;
      }

      &.wo {
        font-size: 36px;
      }
    }

    &__league {
      font-size: 14px;
      font-weight: 400;
      display: block;
    }

    &__date {
      font-size: 14px;
      font-weight: 700;
      color: $color-primary;
    }

    &__icon {
      position: absolute;
      left: 0;
      top: 50%;
      height: 86px;
      width: 86px;
      border-radius: 50%;
      box-shadow: 0px 5px 10px #00000059;
      border: 3px solid #707070;
      overflow: hidden;
      transform: translate(-40%, -50%);
      background: #ffffff;

      img {
        height: 86px;
        width: 86px;
        object-fit: cover;
        object-position: center center;
        display: block;
      }
    }

    &:hover {
      box-shadow: 4px 5px 21px #00000033;
      text-decoration: none;
    }

    &--royale {
      .matches-item {
        &__logo {
          p {
            font-size: 14px;
            line-height: 1.5;
          }
        }

        &__content {
          text-align: left;
        }
      }
    }
  }
}
// end matches

// blog
.blog {
  &-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }

  &-item {
    flex: 0 0 48%;
    width: 48%;
    display: inline-flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    &--related {
      flex: 0 0 100%;
      width: 100%;
      .blog-item {
        &__content {
          flex: 0 0 100%;
          width: 100%;
        }
      }
    }

    &__image {
      flex: 0 0 48%;
      width: 48%;
      min-height: 360px;
      border-radius: 6px;
      position: relative;
      overflow: hidden;

      img {
        height: 360px;
        width: 100%;
        display: block;
        object-fit: cover;
        transition: all 300ms ease-in-out;
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(255, 179, 57, 0.6);
        z-index: 2;
        height: 100%;
        width: 100%;
        border-radius: 6px;
        opacity: 0;
        pointer-events: none;
        transition: all 300ms ease-in-out;
      }

      &:hover {
        &::before {
          opacity: 1;
        }

        img {
          transform: scale(1.06);
        }
      }
    }

    &__content {
      flex: 0 0 48%;
      width: 48%;
    }

    &__tags {
      margin-bottom: 18px;
      li {
        display: inline-block;
        margin-right: 8px;
        border: 1px solid $color-primary;
        border-radius: 6px;
        padding: 4px 10px;
        font-weight: 700;
        font-size: 12px;
      }
    }

    &__title {
      font-size: 16px;
      line-height: 1.2;
      margin-bottom: 10px;
    }

    &__description {
      font-size: 12px;
      line-height: 1.5;
      margin-bottom: 14px;
    }
  }
}
// end blog

// sponsors
.sponsors {
  &-item {
    position: relative;
    margin: 0 40px;

    img {
      display: block;
      object-fit: contain;
      height: 50px;
      filter: brightness(0) grayscale(1);
      opacity: 0.5;
    }
  }
}
// sponsors

// pagination
.pagination {
  display: block;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  margin-top: 50px;

  .ngx-pagination {
    .pagination {
      &-previous,
      &-next {
        &::after,
        &::before {
          display: none !important;
        }
        a {
          &::after,
          &::before {
            margin-right: 0 !important;
            margin-left: 0 !important;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            font-size: 1.5em;
          }
        }
      }
      &-previous {
        a::before {
          content: "\f104" !important;
        }
      }
      &-next {
        a::after {
          content: "\f105" !important;
        }
      }
    }

    li {
      padding: 0;
      color: $color-secondary;

      &.current {
        background: transparent;
        color: $color-primary;
        font-size: 20px;
        font-weight: 700;
      }
    }

    a {
      padding: 0;
      margin: 0 16px;
      font-size: 16px;
      color: rgba(18, 18, 18, 0.5);
      outline: 0;

      &:hover {
        background: transparent;
        color: $color-primary;
        text-decoration: none;
      }
    }
  }
}
// end pagination

.youtube-color {
  color: #c4302b;
}

.facebook-color {
  color: #475993;
}

.twitter-color {
  color: #1da1f2;
}

.tiktok-color {
  color: #fff;
}

.tiktok-color-black {
  color: #000;
}

.linkedin-color {
  color: #0077b5;
}

.whatsapp-color {
  color: #4fce5d;
}

.instagram-gradient {
  text-transform: uppercase;
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 768px) {
  // form
  .form {
    &-control {
      &.half,
      &.two-third,
      &.third {
        flex: 0 0 100%;
        width: 100%;
      }
    }
  }
  // end form
  // title
  .title {
    flex-flow: column;
    align-items: flex-start;

    &__name {
      font-size: 44px;
      padding-right: 0px;
      padding-bottom: 20px;

      &::before {
        left: 0;
        transform: translateY(0);
        bottom: 0;
        height: 1px;
        width: 50px;
        top: unset;
      }
    }

    &__content {
      margin-top: 20px;
      margin-left: 0px;
    }
  }
  // end title

  // filters
  .filters {
    width: 100%;
    flex-flow: column;
    align-items: flex-start;

    &__title {
      margin-bottom: 16px;
    }

    &-list {
      flex-flow: column;
      align-items: flex-start;
    }

    &-item {
      margin-left: 0px;
      margin-bottom: 16px;

      &.hidden {
        display: none;
      }
    }
  }
  // end filters

  // box-teams
  .box-teams {
    &-item {
      flex: 0 0 100%;
      width: 100%;

      &__title {
        font-size: 32px;
      }

      .btn {
        padding: 10px 16px;
        white-space: nowrap;
        font-size: 14px;
      }

      &__image {
        min-height: 160px;

        img {
          height: 160px;
        }
      }

      &:not(:nth-child(3n)) {
        margin-right: 0px;
      }
    }
  }
  // end box-teams

  // teams
  .teams-item {
    flex: 0 0 100%;
    width: 100%;
  }
  // end teams

  // matches
  .matches {
    &-item {
      flex: 0 0 100%;
      width: 100%;
      padding: 64px 16px 16px;
      flex-flow: column;
      margin-bottom: 60px;
      position: relative;

      &__logo {
        min-width: 60px;

        img {
          width: 60px;
          margin: 0 auto;
        }
      }

      &__content {
        margin: 16px 0;
      }

      &__icon {
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%);
      }

      &__score {
        &.empty {
          font-size: 2.4em;
        }
      }

      &--royale {
        .matches-item {
          &__content {
            text-align: center;
          }

          &__logo {
            text-align: center;
          }
        }
      }
    }
  }
  // end matches

  // blog
  .blog {
    &-list {
      flex-direction: column;
    }

    &-item {
      flex: 1;
      width: 100%;
      flex-flow: column;

      &__image {
        flex: 0 0 100%;
        width: 100%;
        min-height: 160px;

        img {
          height: 160px;
        }
      }

      &__tags {
        margin-bottom: 8px;
      }

      &__content {
        margin-top: 16px;
        width: 100%;
      }
    }
  }
  // end blog
}

.sobre__title {
  p {
    font-size: 38px;
    font-weight: 400;
    color: $text-color-secondary;

    strong {
      color: $color-primary;
      font-weight: 700;
    }
  }
}
